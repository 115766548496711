import { useState } from "react";
import { useSiteContext, notNullOrUndefined } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import type { ApolloError } from "@apollo/client";
import { DateTime } from "luxon";

import type { SiteCalendarBookingFragment } from "../generated/gateway-client";
import { BookingStatus, useSiteBookingsCalendarSingleDayQuery } from "../generated/gateway-client";
import type { SiteBookingsFilters } from "../pages/operations/hooks/useSiteBookingsFilters";
import { filterCalendarBookings } from "../pages/operations/models/BookingFilters";

export interface SiteCalendarInterval {
  error: ApolloError | undefined;
  startTime: number;
  endTime: number;
  initialDataLoading: boolean;
  loading: boolean;
  bookings: SiteCalendarBookingFragment[];
  hasMore: boolean;
  fetchMore: () => void;
}

interface Params {
  date: number;
  filters: SiteBookingsFilters;
  searchText: string;
  skip?: boolean;
}

interface IntervalParams {
  startTime: number;
  endTime: number;
  filters: SiteBookingsFilters;
  searchText: string;
  skip?: boolean;
}

const PAGE_SIZE = 30;

function useInterval({ startTime, endTime, filters, searchText, skip }: IntervalParams): SiteCalendarInterval {
  const [loadingMore, setLoadingMore] = useState(false);
  const { uuid: siteUuid, timezone } = useSiteContext();
  const { i18n } = useTranslation();

  const { startDate: _startDate, endDate: _endDate, status: _status, ...applicableFilters } = filters;
  const variables = {
    ...applicableFilters,
    // TODO(ianm): support filtering by site (BK-981)
    siteUuid: [siteUuid],
    startTime,
    endTime,
    status: [BookingStatus.Approved],
    page: { first: PAGE_SIZE },
  };

  const { error, loading, data, fetchMore } = useSiteBookingsCalendarSingleDayQuery({
    variables,
    skip,
    fetchPolicy: "cache-and-network",
  });

  const bookings = data?.siteBookingsList.edges.map((edge) => edge.node).filter(notNullOrUndefined) ?? [];
  const hasMore = data?.siteBookingsList.pageInfo.hasNextPage ?? false;

  const handleFetchMore = () => {
    if (loading || loadingMore || !hasMore) {
      return;
    }

    setLoadingMore(true);
    fetchMore({
      variables: {
        ...variables,
        page: { first: PAGE_SIZE, after: data?.siteBookingsList.pageInfo.endCursor },
      },
      updateQuery(prev, { fetchMoreResult }) {
        return {
          ...fetchMoreResult,
          siteBookingsList: {
            ...fetchMoreResult.siteBookingsList,
            edges: [...prev.siteBookingsList.edges, ...fetchMoreResult.siteBookingsList.edges],
          },
        };
      },
    })
      .catch((e) => console.error(e))
      .finally(() => setLoadingMore(false));
  };

  return {
    startTime,
    endTime,
    error,
    initialDataLoading: loading,
    loading: loading || loadingMore,
    bookings: filterCalendarBookings(bookings, searchText, timezone, i18n.language),
    hasMore,
    fetchMore: handleFetchMore,
  };
}

export function useSingleDayBookingsCalendar({ date, filters, searchText, skip }: Params) {
  const { timezone } = useSiteContext();

  const dt = DateTime.fromMillis(date, { zone: timezone }).startOf("day");

  const startOfDay = dt.toMillis();
  const sixAM = dt.set({ hour: 6 }).toMillis();
  const nineAM = dt.set({ hour: 9 }).toMillis();
  const noon = dt.set({ hour: 12 }).toMillis();
  const threePM = dt.set({ hour: 15 }).toMillis();
  const sixPM = dt.set({ hour: 18 }).toMillis();
  const endOfDay = dt.endOf("day").plus({ milliseconds: 1 }).toMillis();

  const block1 = useInterval({ skip, filters, searchText, startTime: startOfDay, endTime: sixAM });
  const block2 = useInterval({ skip, filters, searchText, startTime: sixAM, endTime: nineAM });
  const block3 = useInterval({ skip, filters, searchText, startTime: nineAM, endTime: noon });
  const block4 = useInterval({ skip, filters, searchText, startTime: noon, endTime: threePM });
  const block5 = useInterval({ skip, filters, searchText, startTime: threePM, endTime: sixPM });
  const block6 = useInterval({ skip, filters, searchText, startTime: sixPM, endTime: endOfDay });

  const blocks = [block1, block2, block3, block4, block5, block6];

  return {
    error: blocks.find((block) => block.error != null)?.error,
    loading: blocks.some((block) => block.initialDataLoading),
    calendar: blocks,
  };
}

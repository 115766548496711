import { formatters, useTranslation } from "@equiem/localisation-eq1";
import { Alert, Button, Form, Table, Text, useTheme, ProgressCircle } from "@equiem/react-admin-ui";
import { RiCloseLine, RiEditLine, RiErrorWarningLine } from "@equiem/react-admin-ui/icons";
import getSymbolFromCurrency from "currency-symbol-map";
import { Field, useFormikContext } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  ResourceCreateAndEditFormPermissionsModal,
  getAudiencesInfo,
} from "./ResourceCreateAndEditFormPermissionsModal";
import {
  type BookableResourceSiteAudienceInput,
  useDestinationDetailsQuery,
} from "../../../../../generated/gateway-client";
import { useCurrencyCode } from "../../../../../hooks/useCurrency";
import type { FormValues } from "../../../../../lib/formValidation";
import { isVisitorManagementEnabled } from "../../../hooks/useIsVisitorManagementEnabled";

type BookableResourceSiteAudience = BookableResourceSiteAudienceInput & { siteName: string };

const MAX_SITE_AUDIENCES_NUMBER = 10;

const hasPaymentRates = (audience: BookableResourceSiteAudience) => {
  return (
    (audience.paymentRateHourly != null && audience.paymentRateHourly > 0) ||
    (audience.paymentRateHalfDay != null && audience.paymentRateHalfDay > 0) ||
    (audience.paymentRateFullDay != null && audience.paymentRateFullDay > 0) ||
    (audience.paymentRateHourlyAfterHours != null && audience.paymentRateHourlyAfterHours > 0) ||
    (audience.paymentRateHourlyWeekend != null && audience.paymentRateHourlyWeekend > 0)
  );
};

export const ResourceCreateAndEditFormPermissions: React.FC = () => {
  const { i18n, t } = useTranslation();
  const fm = useFormikContext<FormValues>();
  const [showModal, setShowModal] = useState(false);
  const [modalIndex, setModalIndex] = useState<number | null>(null);
  const { breakpoints, colors, spacers } = useTheme(true);

  const { data, loading } = useDestinationDetailsQuery({ variables: { uuid: fm.values.site } });
  const currencyCode = useCurrencyCode(fm.values.building);

  const defaultAudience = useMemo(
    () => ({
      site: fm.values.site,
      siteName: data?.destination.name ?? "",
      segmentIds: [],
      segmentSummary: "",
    }),
    [fm.values.site, data?.destination.name],
  );

  const numOwnerSiteAudiences = useMemo(() => {
    return fm.values.siteAudiences.reduce(
      (count, audience) => (audience.site === fm.values.site ? count + 1 : count),
      0,
    );
  }, [fm.values.siteAudiences, fm.values.site]);

  useEffect(() => {
    if (!loading && fm.values.siteAudiences.length > 0 && numOwnerSiteAudiences === 0) {
      void fm.setFieldValue("siteAudiences", [defaultAudience, ...fm.values.siteAudiences]);
    }
  }, [loading, defaultAudience, fm, numOwnerSiteAudiences]);

  const addSiteAudience = useCallback(
    (siteAudience: BookableResourceSiteAudience) => {
      fm.setFieldValue("siteAudiences", [...fm.values.siteAudiences, siteAudience]).catch(console.error);

      setShowModal(false);
    },
    [fm],
  );

  const editSiteAudience = useCallback(
    (siteAudience: BookableResourceSiteAudience, index: number) => {
      const newSiteAudiences = [...fm.values.siteAudiences];
      newSiteAudiences[index] = siteAudience;
      fm.setFieldValue("siteAudiences", newSiteAudiences).catch(console.error);

      setShowModal(false);
      setModalIndex(null);
    },
    [fm],
  );

  const removeSiteAudience = useCallback(
    (index: number) => {
      const newSiteAudiences = [...fm.values.siteAudiences];
      newSiteAudiences.splice(index, 1);
      fm.setFieldValue("siteAudiences", newSiteAudiences).catch(console.error);
    },
    [fm],
  );
  if (loading) {
    return <ProgressCircle size="md" />;
  }

  return (
    <>
      <Form.Group
        error={fm.errors.autoApproveBookings}
        label={t("bookings.resources.bookingApprovals")}
        showTooltip
        tooltipText={t("bookings.resources.bookingApprovalsHint")}
      >
        <Field
          id="autoApproveBookings"
          name="autoApproveBookings"
          label={t("bookings.resources.automaticallyApproveBookings")}
          as={Form.Checkbox}
          disabled={fm.values.allowRecurringBooking === true || fm.isSubmitting}
        />
        {fm.values.allowRecurringBooking === true && (
          <Alert
            className="mt-4"
            size="large"
            variant="gray"
            icon={<RiErrorWarningLine size={18} color={colors.grayscale[50]} />}
            message={t("bookings.resources.allowRecurringBookingInfo")}
          />
        )}
      </Form.Group>

      {process.env.bookingVisitorInvitesEnabled === "true" && isVisitorManagementEnabled(data) && (
        <Form.Group
          error={fm.errors.allowVisitorInvites}
          label={t("visitors.common.visitors")}
          showTooltip
          tooltipText={t("bookings.resources.allowVisitorInvitesTooltip")}
        >
          <Field
            id="allowVisitorInvites"
            name="allowVisitorInvites"
            label={t("bookings.resources.allowVisitorInvites")}
            as={Form.Checkbox}
            disabled={fm.isSubmitting}
          />
        </Form.Group>
      )}

      <Form.Group
        label={t("bookings.resources.whoCanBookThisResource")}
        required
        showTooltip
        tooltipText={t("bookings.resources.whoCanBookHint")}
      >
        <Form.RadioButton
          id="site-audiences-all"
          disabled={fm.isSubmitting}
          label={t("bookings.resources.whoCanBookAll")}
          checked={fm.values.siteAudiences.length === 0}
          onChange={() => {
            fm.setFieldValue("siteAudiences", []).catch(console.error);
          }}
        />
        <Form.RadioButton
          id="site-audiences-selected"
          disabled={fm.isSubmitting}
          label={t("bookings.resources.whoCanBookGroup")}
          checked={fm.values.siteAudiences.length > 0}
          onChange={() => {
            fm.setFieldValue("siteAudiences", [defaultAudience]).catch(console.error);
          }}
        />
      </Form.Group>

      {fm.values.siteAudiences.length > 0 && (
        <div className="site-audiences-table-container">
          <Table.Table className="w-100">
            <thead>
              <tr>
                <Table.Header label={t("common.site")} />
                <Table.Header label={t("bookings.resources.audience")} style={{ width: "35%" }} />
                <Table.Header label={t("bookings.resources.rates")} style={{ width: "35%" }} />
                <Table.Header label="" />
                <Table.Header label="" />
              </tr>
            </thead>
            <tbody>
              {fm.values.siteAudiences.map((audience, i) => (
                <tr key={i}>
                  <td>
                    <Text variant="text" component="span" size="small">
                      {audience.siteName}
                    </Text>
                  </td>
                  <td>
                    <Text variant="text" component="span" size="small">
                      {getAudiencesInfo(audience, t)}
                    </Text>
                  </td>
                  <td>
                    {hasPaymentRates(audience) ? (
                      <>
                        {audience.paymentRateHourly != null && audience.paymentRateHourly > 0 && (
                          <div>
                            <Text variant="text" component="span" size="small">
                              {`${t("bookings.resources.hourly")} - ${formatters.currency(
                                Number(audience.paymentRateHourly),
                                i18n.language,
                                {
                                  currency: currencyCode,
                                },
                              )}`}
                            </Text>
                          </div>
                        )}
                        {audience.paymentRateHalfDay != null && audience.paymentRateHalfDay > 0 && (
                          <div>
                            <Text variant="text" component="span" size="small">
                              {`${t("bookings.resources.halfDay")} - ${formatters.currency(
                                Number(audience.paymentRateHalfDay),
                                i18n.language,
                                {
                                  currency: currencyCode,
                                },
                              )}`}
                            </Text>
                          </div>
                        )}
                        {audience.paymentRateFullDay != null && audience.paymentRateFullDay > 0 && (
                          <div>
                            <Text variant="text" component="span" size="small">
                              {`${t("bookings.resources.fullDay")} - ${formatters.currency(
                                Number(audience.paymentRateFullDay),
                                i18n.language,
                                {
                                  currency: currencyCode,
                                },
                              )}`}
                            </Text>
                          </div>
                        )}
                        {audience.paymentRateHourlyAfterHours != null && audience.paymentRateHourlyAfterHours > 0 && (
                          <div>
                            <Text variant="text" component="span" size="small">
                              {`${t("bookings.resources.hourlyAfterHours")} - ${formatters.currency(
                                Number(audience.paymentRateHourlyAfterHours),
                                i18n.language,
                                {
                                  currency: currencyCode,
                                },
                              )}`}
                            </Text>
                          </div>
                        )}
                        {audience.paymentRateHourlyWeekend != null && audience.paymentRateHourlyWeekend > 0 && (
                          <div>
                            <Text variant="text" component="span" size="small">
                              {`${t("bookings.resources.hourlyWeekend")} - ${formatters.currency(
                                Number(audience.paymentRateHourlyWeekend),
                                i18n.language,
                                {
                                  currency: currencyCode,
                                },
                              )}`}
                            </Text>
                          </div>
                        )}
                      </>
                    ) : (
                      <Text variant="text" component="span" size="small">
                        {t("bookings.resources.defaultSiteAudienceRates")}
                      </Text>
                    )}
                  </td>
                  <td>
                    <RiEditLine
                      className="edit-audience-button"
                      size="16"
                      color={colors.primary}
                      cursor="pointer"
                      onClick={() => {
                        setModalIndex(i);
                        setShowModal(true);
                      }}
                    />
                  </td>
                  <td>
                    {(audience.site !== fm.values.site || numOwnerSiteAudiences > 1) && (
                      <RiCloseLine
                        size="16"
                        color={colors.danger}
                        cursor="pointer"
                        onClick={() => removeSiteAudience(i)}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table.Table>

          {fm.values.siteAudiences.length < MAX_SITE_AUDIENCES_NUMBER && (
            <Button
              type="button"
              variant="secondary"
              size="lg"
              style={{ width: "100%" }}
              onClick={() => setShowModal(true)}
            >
              {t("bookings.resources.addAnotherAudience")}
            </Button>
          )}
        </div>
      )}

      <ResourceCreateAndEditFormPermissionsModal
        showModal={showModal}
        setShowModal={setShowModal}
        modalIndex={modalIndex}
        setModalIndex={setModalIndex}
        resourceOwnerSite={fm.values.site}
        siteAudience={modalIndex != null ? fm.values.siteAudiences[modalIndex] : undefined}
        theOnlyDomesticAudience={
          modalIndex != null &&
          fm.values.siteAudiences[modalIndex].site === fm.values.site &&
          numOwnerSiteAudiences === 1
        }
        segmentsMap={fm.values.siteAudiences
          .filter((_, index) => index !== modalIndex)
          .map(({ site, segmentIds }) => ({
            site,
            segmentIds: segmentIds ?? [],
          }))}
        currency={getSymbolFromCurrency(currencyCode)}
        onAdd={addSiteAudience}
        onEdit={editSiteAudience}
      />

      <style jsx>{`
        .site-audiences-table-container {
          display: flex;
          flex-direction: column;
          gap: ${spacers.s7};
        }
        .site-audiences-table-container :global(th),
        .site-audiences-table-container :global(td) {
          padding: ${spacers.s3};
        }
        @media screen and (max-width: ${breakpoints.md}px) {
          .site-audiences-table-container {
            gap: ${spacers.s5};
          }
        }
      `}</style>
    </>
  );
};

import type { ApolloQueryResult } from "@apollo/client";
import { notNullOrUndefined, stringNotEmpty, CurrentProfile } from "@equiem/lib";
import { useTranslation } from "@equiem/localisation-eq1";
import { useTheme, useWindowDimensions } from "@equiem/react-admin-ui";
import type { FormikHelpers } from "formik";
import { Formik, Form as FormikForm, useFormikContext } from "formik";
import React, { useEffect, useContext } from "react";
import { ResourceCreateAndEditFormMain } from "./main/ResourceCreateAndEditFormMain";
import { ResourceCreateAndEditFormPreview } from "./preview/ResourceCreateAndEditFormPreview";
import { ResourceCreateAndEditFormButtonTray } from "./ResourceCreateAndEditFormButtonTray";
import { ResourceCreateAndEditLoading } from "./ResourceCreateAndEditLoading";
import { ResourceMultiSiteWarning } from "../../ResourceMultiSiteWarning";
import { ResourceCompanyChangeWarning } from "../../ResourceCompanyChangeWarning";
import type { BookableResourceEditQuery } from "../../../../generated/gateway-client";
import { useBookingSettingQuery, useSiteCompaniesQuery } from "../../../../generated/gateway-client";
import { formValidation, type FormValues } from "../../../../lib/formValidation";
import { resourceCreateAndEditFormvalidationSchema } from "../../../../lib/validationSchema";
import { BookingsAuthContext } from "../../../../contexts/BookingsAuthContext";
import { ResourceEditAccessControlContextProvider } from "../../../../contexts/ResourceEditAccessControlContext";

interface Props {
  initialValues: FormValues;
  resourceUuid?: string;
  resourceName?: string;
  resourceOwnerCompany: { uuid: string; name: string } | null | undefined;
  submit: (values: FormValues, actions: FormikHelpers<FormValues>) => Promise<void>;
  refetch?: () => Promise<ApolloQueryResult<BookableResourceEditQuery>>;
}

const FormContextHandlers: React.FC = () => {
  const profile = useContext(CurrentProfile);
  const fm = useFormikContext<FormValues>();
  const { t } = useTranslation();

  const { data: companiesData, loading: companiesLoading } = useSiteCompaniesQuery({
    variables: { destinationUuid: fm.values.site },
    skip: !profile.canManageRegion,
  });
  useEffect(() => {
    const ownerCompany = fm.values.ownerCompanyUuid;
    if (!profile.canManageRegion || companiesLoading || !stringNotEmpty(ownerCompany)) {
      return;
    }

    const companies = (companiesData?.destination.companiesV2?.edges ?? [])
      .map((edge) => edge.node)
      .filter(notNullOrUndefined);
    if (companies.some((c) => c.uuid === ownerCompany)) {
      return;
    }

    // the resource's owner company has been deleted, or is not enabled for the site
    fm.setFieldValue("ownerCompanyUuid", "").catch(console.error);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.canManageRegion, fm.values.ownerCompanyUuid, companiesLoading, companiesData]);

  useEffect(() => {
    fm.validateForm().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  return <></>;
};

export const ResourceCreateAndEditForm: React.FC<Props> = ({
  initialValues,
  resourceUuid,
  resourceName,
  resourceOwnerCompany,
  submit,
  refetch,
}) => {
  const { t } = useTranslation();
  const { canManageSite } = useContext(BookingsAuthContext);
  const { breakpoints } = useTheme(true);
  const { width } = useWindowDimensions();

  const isMobile = width <= breakpoints.md;

  const { data: setting, loading: settingLoading } = useBookingSettingQuery({
    variables: { site: initialValues.site },
    fetchPolicy: "network-only",
    skip: !canManageSite,
  });

  if (settingLoading) {
    return <ResourceCreateAndEditLoading />;
  }

  // don't produce credit card validation errors if the user doesn't have
  // permission to view/modify credit card settings
  const creditCardAvailable = setting?.bookingSetting?.paymentAccountId != null || !canManageSite;

  return (
    <>
      <Formik<FormValues>
        enableReinitialize
        initialValues={initialValues}
        validationSchema={resourceCreateAndEditFormvalidationSchema(t)}
        validate={(values) => formValidation(values, creditCardAvailable, t)}
        validateOnMount={true}
        onSubmit={submit}
      >
        <ResourceEditAccessControlContextProvider>
          <FormikForm autoComplete="off">
            <div className="resource-form-grid">
              <ResourceCreateAndEditFormMain />
              {!isMobile && <ResourceCreateAndEditFormPreview />}
            </div>
            <ResourceCreateAndEditFormButtonTray
              resourceUuid={resourceUuid}
              resourceName={resourceName}
              triggerRefresh={refetch}
            />
          </FormikForm>
          <ResourceMultiSiteWarning />
          <ResourceCompanyChangeWarning originalCompany={resourceOwnerCompany} />
          <FormContextHandlers />
        </ResourceEditAccessControlContextProvider>
      </Formik>
      <style jsx>{`
        .resource-form-grid {
          display: flex;
          flex-direction: row;
        }
        @media screen and (max-width: ${breakpoints.md}px) {
          .resource-form-grid {
            flex-direction: column;
            min-height: 0;
          }
        }
      `}</style>
    </>
  );
};

import React, { useCallback, useState } from "react";
import { Modal, Form as EqForm, Button, useToast, ProgressCircle, remainingCharacters } from "@equiem/react-admin-ui";
import { useTranslation, useErrorTranslation } from "@equiem/localisation-eq1";
import { stringNotEmpty } from "@equiem/lib";
import type { BookingFragmentFragment } from "../../../generated/gateway-client";
import { useUpdateResourceBookingMutationMutation } from "../../../generated/gateway-client";
import { Formik, Form, Field } from "formik";

interface FormValues {
  adminNote?: string | null;
}

interface P {
  booking: BookingFragmentFragment;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}
export const BookingDetailsAdminNoteFormModal: React.FC<P> = ({ booking, showModal, setShowModal }) => {
  const { t } = useTranslation();
  const { tError } = useErrorTranslation();
  const toast = useToast();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [mutation, { loading }] = useUpdateResourceBookingMutationMutation();

  const adminNoteMaxLength = 500;

  const onModalClose = useCallback(
    (forceClose = false) => {
      if (forceClose) {
        setShowModal(false);
        setShowConfirmationModal(false);

        return;
      }

      setShowConfirmationModal(dirty);
      setShowModal(dirty);
    },
    [setShowConfirmationModal, dirty, setShowModal],
  );

  const submit = async (values: FormValues) => {
    try {
      await mutation({
        variables: {
          uuid: booking.uuid,
          input: {
            adminNote: stringNotEmpty(values.adminNote?.trim()) ? values.adminNote?.trim() : null,
          },
        },
      });

      onModalClose(true);
    } catch (e: unknown) {
      console.error(e);
      toast.negative(tError(e));
    }
  };

  return (
    <>
      <Modal.Dialog
        title={t("bookings.operations.notes")}
        show={showModal}
        onHide={() => onModalClose()}
        hideOnEsc={true}
        centered
        scrollable
        size="md"
      >
        <Modal.Header closeButton={true} noBorder={false} intro={t("bookings.operations.adminNote")} />
        <Formik<FormValues>
          initialValues={{ adminNote: booking.adminNote }}
          onSubmit={submit}
          innerRef={(formikActions) => setDirty(formikActions?.dirty ?? false)}
        >
          {({ submitForm, values }) => (
            <Form autoComplete="off">
              <Modal.Body>
                <EqForm.Group
                  className="admin-note"
                  hint={t("common.remainingCharacters", {
                    count: remainingCharacters(adminNoteMaxLength, values.adminNote?.length ?? 0),
                  })}
                  label={t("bookings.operations.adminNote")}
                  tooltipText={t("bookings.operations.adminNoteTooltip")}
                  showTooltip={true}
                >
                  <Field
                    placeholder={t("bookings.operations.addAdminNote")}
                    as={EqForm.Textarea}
                    id="adminNote"
                    name="adminNote"
                    maxLength={adminNoteMaxLength}
                  />
                </EqForm.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button className="mr-2" size="md" variant="ghost" onClick={() => onModalClose()}>
                  {t("common.cancel")}
                </Button>
                <Button
                  size="md"
                  variant="primary"
                  disabled={loading}
                  onClick={() => {
                    submitForm().catch(console.log);
                  }}
                >
                  {loading && <ProgressCircle size="xs" className="mr-2" />} {t("bookings.operations.saveNote")}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal.Dialog>
      {showConfirmationModal && (
        <Modal.Dialog
          title={t("common.areYouSure")}
          show
          onHide={() => setShowConfirmationModal(true)}
          hideOnEsc={true}
          supportsMobile={true}
          mobileView
          hideOnClick={false}
          focusTrapOptions={{ initialFocus: false }}
          size="md"
          className="cancel-action-modal"
          centered
        >
          <Modal.Header closeButton={false} noBorder={true} />
          <Modal.Body>{t("common.formCloseConfirmation")}</Modal.Body>
          <Modal.Footer>
            <Button variant="ghost" className="mr-4" onClick={() => setShowConfirmationModal(false)}>
              {t("common.cancelNo")}
            </Button>
            <Button variant="danger" className="cancel-action" onClick={() => onModalClose(true)}>
              {t("common.yesCancel")}
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      )}
    </>
  );
};

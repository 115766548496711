import { stringIsEmpty } from "@equiem/lib";
import { useApolloErrorTranslation, useTranslation } from "@equiem/localisation-eq1";
import { Form, remainingCharacters, useTheme } from "@equiem/react-admin-ui";
import type { FieldProps } from "formik";
import { Field, useFormikContext } from "formik";
import React, { useEffect } from "react";
import { ResourceTypes } from "../ResourceTypes";
import { CKEditorNoSSR } from "../../../../../components/CKEditorNoSSR";
import type { FormValues } from "../../../../../lib/formValidation";
import { useAuthorizedDestinationDetails } from "../../../hooks/useAuthorizedDestinationDetails";

const TITLE_MAX_LENGTH = 100;

export const ResourceCreateAndEditFormDetails: React.FC = () => {
  const { t } = useTranslation();
  const { tError } = useApolloErrorTranslation();
  const fm = useFormikContext<FormValues>();
  const { breakpoints, spacers } = useTheme(true);

  const { buildings, loading, error } = useAuthorizedDestinationDetails(fm.values.site, {
    includeBuildingLevels: fm.values.level == null ? [] : [fm.values.level],
  });

  const selectedBuilding = buildings.find((b) => b.uuid === fm.values.building);
  const levels = selectedBuilding?.buildingLevels ?? [];

  useEffect(() => {
    if (stringIsEmpty(fm.values.building) && buildings.length > 0) {
      fm.setFieldValue("building", buildings[0].uuid).catch(console.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fm.values.building, buildings]);

  return (
    <>
      <div className="title-container">
        <Form.Group
          className="resource-title"
          error={fm.touched.title === true ? fm.errors.title : undefined}
          label={t("bookings.resources.resourceTitle")}
          hint={t("common.remainingCharacters", {
            count: remainingCharacters(TITLE_MAX_LENGTH, fm.values.title.length),
          })}
          required
        >
          <Field
            id="title"
            name="title"
            placeholder={t("bookings.resources.resourceTitleHint")}
            maxLength={TITLE_MAX_LENGTH}
            as={Form.Input}
            disabled={fm.isSubmitting}
          />
        </Form.Group>
      </div>
      <div className="form-group-container">
        <ResourceTypes />
        <Form.Group error={fm.errors.displayCapacity} label={t("bookings.resources.resouceCapacity")}>
          <Field
            type="number"
            id="displayCapacity"
            name="displayCapacity"
            placeholder={t("bookings.resources.resouceCapacityHint")}
            as={Form.Input}
            disabled={fm.isSubmitting}
            min={0}
            max={9999}
          />
        </Form.Group>
      </div>
      {loading || error != null ? (
        <div className="mb-6">
          {loading && <div>{t("common.loading")}...</div>}
          {error != null && <div>{tError(error)}</div>}
        </div>
      ) : (
        <>
          <Form.Group label={t("bookings.resources.resourceAddress")}>
            <span>{selectedBuilding?.address ?? "-"}</span>
          </Form.Group>
          <div className="form-group-container">
            <Form.Group label={t("bookings.resources.resourceBuilding")} hasError={fm.errors.building != null}>
              <Field
                id="building"
                name="building"
                placeholder={t("bookings.resources.resourceBuildingHint")}
                disabled={fm.isSubmitting}
              >
                {({ field }: FieldProps) => (
                  <Form.Select
                    {...field}
                    name="building"
                    onChange={(e) => {
                      // When a building is selected, the default value for level should also update.
                      const newBuilding = e.currentTarget.value;
                      const newBuildingLevels = buildings.find((b) => b.uuid === newBuilding)?.buildingLevels ?? [];
                      const newLevel = newBuildingLevels.length > 0 ? newBuildingLevels[0].uuid : "";
                      fm.setFieldValue("building", newBuilding).catch(console.error);
                      fm.setFieldValue("level", newLevel).catch(console.error);
                    }}
                    value={fm.values.building}
                  >
                    {buildings.map((b) => (
                      <option key={b.uuid} value={b.uuid}>
                        {b.name}
                      </option>
                    ))}
                  </Form.Select>
                )}
              </Field>
            </Form.Group>
            <Form.Group label={t("bookings.resources.resourceLevel")}>
              <Field
                id="level"
                name="level"
                placeholder={t("bookings.resources.selectLevel")}
                disabled={fm.isSubmitting}
              >
                {({ field }: FieldProps) => (
                  <Form.Select
                    {...field}
                    name="level"
                    onChange={(e) => {
                      fm.setFieldValue("level", e.currentTarget.value).catch(console.error);
                    }}
                    value={fm.values.level}
                  >
                    {levels.map((l) => (
                      <option key={l.uuid} value={l.uuid}>
                        {l.name}
                      </option>
                    ))}
                  </Form.Select>
                )}
              </Field>
            </Form.Group>
          </div>
        </>
      )}
      <Form.Group error={fm.errors.description} label={t("bookings.resources.resourceDescrption")}>
        <Field
          id="description"
          name="description"
          placeholder={t("bookings.resources.descriptionPlaceholder")}
          as={CKEditorNoSSR}
          toolbar={[
            "heading",
            "|",
            "bold",
            "italic",
            "|",
            "link",
            "|",
            "bulletedList",
            "numberedList",
            "|",
            "outdent",
            "indent",
            "|",
            "undo",
            "redo",
          ]}
          disabled={fm.isSubmitting}
        />
      </Form.Group>
      <style jsx>{`
        .form-group-container {
          display: flex;
          gap: ${spacers.s5};
        }
        .form-group-container :global(.form-header label) {
          line-height: 14px;
        }
        @media screen and (max-width: ${breakpoints.md}px) {
          .form-group-container {
            flex-direction: column;
          }
        }
      `}</style>
    </>
  );
};
